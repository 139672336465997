import SeparatorLine from "@ds/atoms/SeparatorLine";
import NavMenuItem from "@ds/molecules/NavMenuItem";
import AddIcon from "@mui/icons-material/Add";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import style from "./style.module.css";

function FilterBar() {
  return (
    <div className={style.wrapper}>
      <div className={style.leftSection}>
        <NavMenuItem Icon={<SearchIcon />} label="Search" collapsed={true} />
        <SeparatorLine />
        <NavMenuItem Icon={<VisibilityOffIcon />} label="Hide fields" />
        <NavMenuItem Icon={<CallSplitIcon className={style.rotateRight} />} label="Variant" />
        <NavMenuItem Icon={<AddIcon />} label="Filter" />
      </div>
      <div className={style.rightSection}>
        <NavMenuItem Icon={<BrokenImageOutlinedIcon />} label="Design preview" />
      </div>
    </div>
  );
}

export default FilterBar;
