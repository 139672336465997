import { projectNameAtom } from "@/stores/Project";
import { useAtomValue } from "jotai";
import style from "./style.module.css";

function NavBar() {
  const projectName = useAtomValue(projectNameAtom);
  return (
    <div className={style.wrapper}>
      <span className={style.title}>{projectName}</span>
    </div>
  );
}

export default NavBar;
