import { IFActualChange } from "@shared/types/ActualChange";
import { Atom, atom, WritableAtom } from "jotai";

// Will need to be increased for product, 5 is for testing
export const FETCH_COUNT = 5;

export const libraryActivityAtom = atom<{ activity: IFActualChange[] | null; skip: number }>({
  activity: null,
  skip: 0,
});

type GetActivityCall = (args: { skip: number; limit: number }) => Promise<IFActualChange[]>;

// We use a write atom here instead of an async derived atom because doing so
// would cause the "read" to become not-pure since libraryActivityAtom grows
// each time more activity is fetched
// I Jotai "read" must be pure, so we can't use a derived atom
export const fetchMoreLibraryActivityAtom = atom(null, async (get, set, getActivityCall: GetActivityCall) => {
  const libraryActivity = get(libraryActivityAtom);
  const result = await getActivityCall({
    skip: libraryActivity.skip,
    limit: FETCH_COUNT,
  });

  set(libraryActivityAtom, {
    activity: [...(libraryActivity.activity || []), ...result],
    skip: libraryActivity.skip + FETCH_COUNT,
  });
});

export const refreshLibraryActivityAtom = atom(null, async (get, set, getActivityCall: GetActivityCall) => {
  const libraryActivity = get(libraryActivityAtom);
  const result = await getActivityCall({
    skip: libraryActivity.skip - FETCH_COUNT,
    limit: (libraryActivity.activity || []).length,
  });

  set(libraryActivityAtom, { activity: result, skip: libraryActivity.skip });
});

export const selectedActivityAtomsAtom = atom<
  Record<
    string,
    {
      refresh: WritableAtom<null, [], Promise<void>>;
      activity: Atom<Promise<IFActualChange[]>>;
    }
  >
>({
  invalid: {
    refresh: atom(null, async (get, set) => {}),
    activity: atom(async () => []),
  },
});
export const createSelectedActivityAtom = (id: string, getActivityCall: () => Promise<IFActualChange[]>) => {
  const activityTriggerAtom = atom(0);

  const refreshActivityAtom = atom(null, async (get, set) => {
    set(activityTriggerAtom, get(activityTriggerAtom) + 1);
  });

  const activityAtom = atom<Promise<IFActualChange[]>>(async (get) => {
    get(activityTriggerAtom);

    let result: IFActualChange[] = await getActivityCall();

    return result;
  });

  return {
    refresh: refreshActivityAtom,
    activity: activityAtom,
  };
};
