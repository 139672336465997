import { IFDittoProjectBlock } from "@shared/types/DittoProject";
import { IFDittoProjectData } from "@shared/types/http/DittoProject";
import { IFTextItem } from "@shared/types/TextItem";
import { createHttpRequest } from "./lib/createHttpRequest";

export const getProject = createHttpRequest<{ projectId: string }, IFDittoProjectData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}`;
  },
});

export const getTextItems = createHttpRequest<{ ids: string[]; projectId: string }, IFTextItem[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});

export const getBlocks = createHttpRequest<{ ids: string[]; projectId: string }, IFDittoProjectBlock[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});
