import classNames from "classnames";
import React from "react";
import { BlockIcon } from "../../../helpers/Icons";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import Toggle from "../../atoms/Toggle";
import style from "./index.module.css";

interface INavigatorRowProps {
  children: React.ReactNode;

  pressed: boolean;
  onChange: (pressed: boolean) => void;

  type?: "default" | "block" | "block-child";
  state?: "default" | "nested";

  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export function NavigatorRow(props: INavigatorRowProps) {
  const { children, pressed, onChange, type = "default", state = "default", disabled = false } = props;

  return (
    <Toggle
      pressed={pressed}
      onChange={onChange}
      style={props.style}
      leadingIcon={type === "block" ? <Icon Icon={<BlockIcon />} size="xxs" /> : undefined}
      className={classNames(
        style.NavigatorRowWrapper,
        {
          [style[type]]: true,
          [style[state]]: true,
        },
        props.className
      )}
      disabled={disabled}
      color="nav-select"
      data-testid="navigator-row"
    >
      {typeof children === "string" && (
        <Text className={style.defaultText} disabled={disabled} size="small">
          {children}
        </Text>
      )}
      {typeof children !== "string" && children}
    </Toggle>
  );
}

export default NavigatorRow;
