import { useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { WEBSOCKET_EVENTS } from "../../../../../shared/common/constants";
import { WEBSOCKET_URL } from "../../../../../shared/types/websocket";
import { useAuthenticatedAuth } from "../../../../store/AuthenticatedAuthContext";

interface WebsocketsHandlerProps {
  projectId: string;
}

// Only general project Websockets should be handled here.
// Otherwise, place in a domain specific websocket handler component.
export function WebsocketsHandler(props: WebsocketsHandlerProps) {
  const { sendMessage, readyState } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  const { getTokenSilently } = useAuthenticatedAuth();

  useEffect(() => {
    async function sendDocSubscribeMsg() {
      if (!props.projectId) {
        return;
      }

      const subscribeToDocMsg = {
        messageType: WEBSOCKET_EVENTS.NEW_DOC_SUBSCRIPTION,
        token: await getTokenSilently(),
        docId: props.projectId,
      };
      sendMessage(JSON.stringify(subscribeToDocMsg));
    }

    if (readyState === 1) {
      sendDocSubscribeMsg();
    }
  }, [readyState, props.projectId, sendMessage, getTokenSilently]);

  return null;
}
