import { atom } from "jotai";
import { atomWithLocation } from "jotai-location";

const locationAtom = atomWithLocation();
// MARK: - Param Constants
const DETAILS_PANEL_PARAM = "detailsPanel";
const TEXT_ITEM_IDS_PARAM = "textItemIds";
const BLOCK_IDS_PARAM = "blockIds";

export const selectedTextItemIdsAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return [];
    }

    const textItemIds = location.searchParams.get(TEXT_ITEM_IDS_PARAM);
    return textItemIds ? textItemIds.split(",") : [];
  },
  (get, set, newTextItemIds: string[]) => {
    const location = get(locationAtom);
    const newSearchParams = new URLSearchParams(location.searchParams);

    if (newTextItemIds.length > 0) {
      newSearchParams.set(TEXT_ITEM_IDS_PARAM, newTextItemIds.join(","));
    } else {
      newSearchParams.delete(TEXT_ITEM_IDS_PARAM);
    }

    set(locationAtom, {
      ...location,
      searchParams: newSearchParams,
    });
  }
);

export const selectedBlockIdsAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return [];
    }

    const blockIds = location.searchParams.get(BLOCK_IDS_PARAM);
    return blockIds ? blockIds.split(",") : [];
  },
  (get, set, newBlockIds: string[]) => {
    const location = get(locationAtom);
    const newSearchParams = new URLSearchParams(location.searchParams);

    if (newBlockIds.length > 0) {
      newSearchParams.set(BLOCK_IDS_PARAM, newBlockIds.join(","));
    } else {
      newSearchParams.delete(BLOCK_IDS_PARAM);
    }

    set(locationAtom, {
      ...location,
      searchParams: newSearchParams,
    });
  }
);

export const clearSelectionActionAtom = atom(null, (get, set) => {
  set(selectedTextItemIdsAtom, []);
  set(selectedBlockIdsAtom, []);
});

export const selectionTypeAtom = atom((get) => {
  const blocks = get(selectedBlockIdsAtom);
  const textItems = get(selectedTextItemIdsAtom);

  if (textItems.length > 0) {
    return "text";
  }

  if (blocks.length > 0) {
    return "blocks";
  }

  return null;
});
// MARK: - Details Panel

const DetailsPanelContexts = ["PROJECT", "EDIT"] as const;
export type DetailsPanelContext = (typeof DetailsPanelContexts)[number];

export const detailsPanelContextAtom = atom<DetailsPanelContext>((get) => {
  const blocks = get(selectedBlockIdsAtom);
  const textItems = get(selectedTextItemIdsAtom);

  if (blocks.length > 0 || textItems.length > 0) {
    return "EDIT";
  }
  return "PROJECT";
});

const DetailsPanelEditStates = ["EDIT", "ACTIVITY", "VARIANTS"] as const;
export type DetailsPanelEditStates = (typeof DetailsPanelEditStates)[number];

export const detailsPanelEditStateAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "EDIT";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as DetailsPanelEditStates;

    if (state === null || !DetailsPanelEditStates.includes(state)) {
      return "EDIT";
    }

    return state;
  },
  (get, set, newPanel: DetailsPanelEditStates) => {
    const location = get(locationAtom);
    const newSearchParams = new URLSearchParams(location.searchParams);

    if (newPanel) {
      newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
    } else {
      newSearchParams.delete(DETAILS_PANEL_PARAM);
    }

    set(locationAtom, {
      ...location,
      searchParams: newSearchParams,
    });
  }
);

const DetailsPanelProjectStates = ["ACTIVITY", "COMMENTS"] as const;
export type DetailsPanelProjectStates = (typeof DetailsPanelProjectStates)[number];

export const detailsPanelProjectStateAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "ACTIVITY";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as DetailsPanelProjectStates;

    if (state === null || !DetailsPanelProjectStates.includes(state)) {
      return "ACTIVITY";
    }

    return state;
  },
  (get, set, newPanel: DetailsPanelProjectStates) => {
    const location = get(locationAtom);
    const newSearchParams = new URLSearchParams(location.searchParams);

    if (newPanel) {
      newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
    } else {
      newSearchParams.delete(DETAILS_PANEL_PARAM);
    }

    set(locationAtom, {
      ...location,
      searchParams: newSearchParams,
    });
  }
);
