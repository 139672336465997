import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import style from "./style.module.css";

function Instances() {
  return (
    <div className={style.wrapper}>
      <Text color="secondary" weight="medium" size="small">
        8 instances in design
      </Text>
      <Button variant="text">
        <Text color="action" size="micro">
          Preview
        </Text>
      </Button>
    </div>
  );
}

export default Instances;
