import * as ScrollArea from "@radix-ui/react-scroll-area";
import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  children: React.ReactNode;

  className?: string;
  style?: React.CSSProperties;
}

export function Scrollbar(props: IProps) {
  return (
    <ScrollArea.Root
      style={props.style}
      className={classNames(style.ScrollbarWrapper, props.className)}
      data-testid="scrollbar"
    >
      <ScrollArea.Viewport className={style.viewport}>{props.children}</ScrollArea.Viewport>

      <ScrollArea.Scrollbar orientation="vertical" className={style.scrollbar}>
        <ScrollArea.Thumb className={style.thumb} />
      </ScrollArea.Scrollbar>

      <ScrollArea.Scrollbar orientation="horizontal" className={style.scrollbar}>
        <ScrollArea.Thumb className={style.thumb} />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
}

export default Scrollbar;
