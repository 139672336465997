export const JobNames = {
  AutoComponentizeProjectJob: "auto-componentize-project",
  ComponentImportJob: "component-bulk-import",
  ComponentSuggestionsJob: "component-suggestions",
  ConfirmFrameRemoval: "confirm-frame-removal",
  CronRefreshCIFigmaAuthTokensJob: "cron-refresh-ci-figma-auth-tokens",
  DailyProductMetricsJob: "daily-product-metrics",
  ExampleCronJob: "example-cron-job",
  FigmaGetBranchInfoJob: "figma-get-branch-info",
  FigmaGetPagesJob: "figma-get-pages",
  FigmaPreviewJob: "figma-preview",
  FigmaCacheRefreshJob: "figma-cache-refresh",
  FigmaCacheRefreshPageJob: "figma-cache-refresh-page",
  FigmaCacheRefreshCleanupJob: "figma-cache-refresh-cleanup",
  FigmaCacheRefreshCommitJob: "figma-cache-refresh-commit",
  FigmaSyncNewFramesJob: "figma-sync-new-frames",
  ImportJob: "import",
  OnboardingChecklistJobQueue: "onboarding-checklist",
  ResyncJob: "resync",
  SuggestionsJob: "suggestions",
  SystemMetricsCronJob: "system-metrics-cron-job",
  WebhookJob: "webhooks",
} as const;
