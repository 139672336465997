import * as RadixToggle from "@radix-ui/react-toggle";
import classNames from "classnames";
import React from "react";
import Icon from "../Icon";
import style from "./index.module.css";

interface IToggleProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;

  variant?: "base" | "icon";

  /**
   * Handler for when toggle is pressed.
   *
   * @param pressed The pressed state of the toggle.
   * @returns void
   */
  onChange: (pressed: boolean) => void;

  /**
   * The controlled pressed state of the toggle. Must be used in conjunction w/onChange handler.
   */
  pressed: boolean;

  /**
   * Flag for whether toggle is initially pressed.
   */
  isToggled?: boolean;

  /**
   * The icon to be rendered. Intended for use with @mui/icons-material.
   * You can pass in an icon like Icon={\<CheckIcon \/>}.
   * You can also pass in a straight-up SVG element like Icon={\<svg>...\</svg>}.
   * The icon will inherit the color of the label, but can be overridden by passing in the iconColor prop.
   *
   * This is *not* the way to render an icon-only button. Use the variant="icon" prop and pass in the icon
   * as the children instead.
   */
  leadingIcon?: React.ReactNode | React.ReactSVGElement;
  iconSize?: "xs" | "small" | "base";

  /**
   * Size of toggle container. Overrides icon size prop.
   */
  size?: "base" | "small";
  state?: "default" | "focus";
  color?: "primary" | "secondary" | "tertiary" | "danger" | "nav-select";

  disabled?: boolean;
}

export function Toggle({
  variant = "base",
  color = "primary",
  state = "default",
  size = "base",
  onChange,
  pressed,
  isToggled = false,
  iconSize = "small",
  leadingIcon,
  disabled = false,
  ...props
}: IToggleProps) {
  return (
    <RadixToggle.Root
      style={props.style}
      className={classNames(
        style.ToggleWrapper,
        {
          [style[color]]: true,
          [style[state]]: true,
          [style["small"]]: size === "small",
        },
        props.className
      )}
      onPressedChange={(pressed) => onChange(pressed)}
      pressed={pressed}
      disabled={disabled}
      data-testid="toggle"
    >
      {leadingIcon && (
        <Icon Icon={leadingIcon} size={size === "small" ? "small" : iconSize} style={{ color: "inherit" }} />
      )}
      {variant !== "icon" && props.children}
      {variant === "icon" && (
        <Icon Icon={props.children} size={size === "small" ? "small" : iconSize} style={{ color: "inherit" }} />
      )}
    </RadixToggle.Root>
  );
}

export default Toggle;
