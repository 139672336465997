import * as Tab from "@radix-ui/react-tabs";
import classNames from "classnames";
import React from "react";
import Label from "../../atoms/Label";
import style from "./index.module.css";

interface ITab {
  id: string;
  label: string;
  leadingIcon?: React.ReactNode | React.ReactSVGElement;
  trailingIcon?: React.ReactNode | React.ReactSVGElement;
  disabled?: boolean;
}

interface IProps {
  tabs: ITab[];
  defaultTabIndex?: number; // defaults to 0
  className?: string;
  listClassName?: string;
  triggerClassName?: string;
  style?: React.CSSProperties;
  onValueChange?: (value: string) => void;
  value?: string;
}

export function Tabs(props: IProps) {
  const tabIds = props.tabs.map((tab) => tab.id);

  const defaultTabId = props.defaultTabIndex ? tabIds[props.defaultTabIndex] : tabIds[0];

  return (
    <Tab.Root
      value={props.value}
      defaultValue={defaultTabId}
      className={classNames(style.tabRoot, props.className)}
      data-testid="tabs"
      onValueChange={props.onValueChange}
    >
      <Tab.List className={classNames(style.tabList, props.listClassName)}>
        {props.tabs.map((tab) => (
          <Tab.Trigger
            key={tab.id}
            value={tab.id}
            className={classNames(props.triggerClassName, style.tabTrigger, {
              [style.disabled]: tab.disabled,
            })}
            disabled={tab.disabled}
            data-testid={`tabs-tab-${tab.id}`}
          >
            <Label leadingIcon={tab.leadingIcon} trailingIcon={tab.trailingIcon} className={style.tabLabel}>
              {tab.label}
            </Label>
          </Tab.Trigger>
        ))}
      </Tab.List>
    </Tab.Root>
  );
}

export default Tabs;
