import Add from "@mui/icons-material/Add";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Close from "@mui/icons-material/Close";
import classNames from "classnames";
import React from "react";
import { BlockIcon } from "../../../helpers/Icons";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Label from "../../atoms/Label";
import style from "./index.module.css";

// TODO: this type should be derived from a top-level shared type.
interface ITextItem {
  text: string;
}

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  name: string;
  children: React.ReactNode;
  state?: "default" | "focus" | "active" | "dragging" | "dropping";

  onMoveBlockUp: () => void;
  onMoveBlockDown: () => void;
  onDeleteBlock: () => void;
  onAddNewTextItem: () => void;
}

export function TextItemBlock(props: IProps) {
  return (
    <div
      style={props.style}
      className={classNames(style.TextItemBlockWrapper, props.className)}
      data-testid="text-item-block"
    >
      <div className={style.header}>
        <div className={style.details}>
          <Label leadingIcon={<BlockIcon />} iconSize="xs">
            {props.name}
          </Label>
        </div>
        <div className={style.actions}>
          <Button className={style.iconButton} variant="icon" iconSize="xs" onClick={props.onMoveBlockUp}>
            <ArrowUpward />
          </Button>

          <Button className={style.iconButton} variant="icon" iconSize="xs" onClick={props.onMoveBlockDown}>
            <ArrowDownward />
          </Button>

          <Button className={style.iconButton} variant="icon" iconSize="xs" onClick={props.onDeleteBlock}>
            <Close />
          </Button>
        </div>
      </div>

      {props.children ? (
        <div className={style.textItems}>{props.children}</div>
      ) : (
        <button className={style.empty}>
          <Icon Icon={<Add />} size="xs" color="inherit" />
          Add text to this block
        </button>
      )}

      <div className={style.footer}>
        <Button
          className={style.addTextItemButton}
          variant="text"
          size="small"
          leadingIcon={<Add />}
          iconColor="inherit"
          iconSize="xs"
        >
          Add text item
        </Button>
      </div>
    </div>
  );
}

export default TextItemBlock;
