import z from "zod";
import { FrontendSchema, ZObjectId } from "../lib";

export namespace UpsertGroup {
  export const request = {
    body: z.object({
      name: z.string().optional(),
      // TODO: update type once we have zod schemas for text items
      text_items: z.array(z.any()).optional(),
      linking_enabled: z.boolean().optional(),
      group_index: z.number().optional(),
    }),
  };

  export const response = {
    body: z.object({
      created: z
        .object({
          apiID: z.string().optional(),
        })
        .optional(),
      updated: z
        .object({
          apiID: z.string().optional(),
          apiIdsByTextItemId: z.record(z.string()).optional(),
          commentThreadUpdateMap: z.record(z.string(), z.any()).optional(),
        })
        .optional(),
    }),
  };

  export type Request = {
    body: z.infer<typeof request.body>;
  };

  export type Response = {
    body: z.infer<typeof response.body>;
  };
}

// Project

// BASE

// Block

export const BaseBlock = z.object({
  _id: z.string(),
  name: z.string().optional(),
  apiID: z.string().nullable(),
});

export type BaseBlock = z.infer<typeof BaseBlock>;

// Group

export const GroupFigmaIntegration = z.object({
  frame_id: z.string(), // TODO: the DB allows null and undefined, but the API doesn't
  frame_name: z.string().nullable().optional(),
  frame_name_synced_with_group_name: z.boolean().optional(),
  page_id: z.string().nullable().optional(),
  previews: z
    .object({
      fullSize: z.string().nullable().optional(),
    })
    .optional(),
  applied_variant_id: z.string().nullable().optional(),
  position: z
    .object({
      x: z.number().optional(),
      y: z.number().optional(),
      width: z.number().optional(),
      height: z.number().optional(),
    })
    .optional(),
});
export type GroupFigmaIntegration = z.infer<typeof GroupFigmaIntegration>;

export const GroupIntegrations = z.object({
  figma: GroupFigmaIntegration.or(z.object({})),
});

export type GroupIntegrations = z.infer<typeof GroupIntegrations>;

export const BaseGroup = z.object({
  _id: z.string(),
  name: z.string(),
  apiID: z.string().nullable(),
  blocks: z.array(BaseBlock),
  pinned: z.boolean(),
  hidden: z.boolean(),
  nonblocks_collapsed: z.boolean().optional(),
  integrations: GroupIntegrations,
  linking_enabled: z.boolean(),
});

export type BaseGroup = z.infer<typeof BaseGroup>;

// Project

export const DocumentFigmaIntegration = z.object({
  file_id: z.string().nullable().optional(),
  file_name: z.string().nullable().optional(),
  selected_pages: z.array(
    z.object({
      name: z.string(),
      figma_id: z.string(),
    })
  ),
  branch_id: z.string().nullable().optional(),
  resynced_at: z.date().nullable().optional(),
  previews_updated_at: z.date().nullable().optional(),
  ditto_component_key: z.string().nullable().optional(),
  position: z
    .object({
      x: z.number(),
      y: z.number(),
      width: z.number(),
      height: z.number(),
    })
    .nullable()
    .optional(),
  text_layer_rules: z.object({
    show_component_icon: z.boolean(),
    show_status_icon: z.boolean(),
    show_api_id: z.boolean(),
  }),
});

export type DocumentFigmaIntegration = z.infer<typeof DocumentFigmaIntegration>;

export const DocumentIntregrations = z.object({
  figma: DocumentFigmaIntegration.or(z.object({})),
  slack: z.object({
    channel_id: z.string().nullable(),
    channel_name: z.string().nullable(),
  }),
});

export type DocumentIntregrations = z.infer<typeof DocumentIntregrations>;

export const DocumentFeatureFlags = z.object({
  rich_text: z.boolean(),
});

export type DocumentFeatureFlags = z.infer<typeof DocumentFeatureFlags>;

const BaseProject = z.object({
  _id: z.string(),
  workspace_ID: z.string(),
  developer_mode_enabled: z.boolean(),
  integrations: DocumentIntregrations,
  feature_flags: DocumentFeatureFlags,
  doc_name: z.string(),
  tags: z.array(z.string()),
  groups: z.array(BaseGroup),
  date_time_created: z.date(),
  time_last_resync: z.date().nullable(),
  edited_at: z.date(),
  folder_id: z.string().nullable(),
  is_locked: z.boolean(),
});

export type BaseProject = z.infer<typeof BaseProject>;

// Lean

export const LeanBlock = BaseBlock.extend({
  comps: z.array(z.string()),
});

export const LeanGroup = BaseGroup.extend({
  blocks: z.array(LeanBlock),
  comps: z.array(z.string()),
});

const LeanProject = BaseProject.extend({
  groups: z.array(LeanGroup),
});

export type LeanProject = z.infer<typeof LeanProject>;

// MARK: - Full Project

export const TextItemFigmaIntegration = z.object({
  position: z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
  }),
  type: z.string(),
});

export type TextItemFigmaIntegration = z.infer<typeof TextItemFigmaIntegration>;

export const TextItemIntegrations = z.object({
  figma: TextItemFigmaIntegration.or(z.object({})),
});

export type TextItemIntegrations = z.infer<typeof TextItemIntegrations>;

export const TextItem = z.object({
  _id: z.string(),
  assignee: z.string().nullable(),
  workspace_ID: z.string(),
  status: z.union([z.literal("NONE"), z.literal("WIP"), z.literal("REVIEW"), z.literal("FINAL"), z.literal(null)]),
  tags: z.array(z.string()),
  text: z.string(),
  apiID: z.string().nullable(),
  integrations: TextItemIntegrations,
  figma_node_ID: z.string().optional().nullable(),
  is_hidden: z.boolean().optional(),
  // TODO: REFACTOR Add to this type
});

export type TextItem = z.infer<typeof TextItem>;

export const FullBlock = BaseBlock.extend({
  comps: z.array(TextItem),
});

export type FullBlock = z.infer<typeof FullBlock>;

export const FullGroup = BaseGroup.extend({
  blocks: z.array(FullBlock),
  comps: z.array(TextItem),
});

export type FullGroup = z.infer<typeof FullGroup>;

export const FullProject = BaseProject.extend({
  groups: z.array(FullGroup),
});

export type FullProject = z.infer<typeof FullProject>;

export type LinkedFullProject = FullProject & {
  integrations: DocumentIntregrations & {
    figma: DocumentFigmaIntegration;
  };
};

// Helpers for Text Items

export const isTextItemConnectedToFigma = function isTextItemConnectedToFigma(
  textItem: TextItem
): textItem is TextItem & {
  figma_node_ID: string;
  integrations: TextItemIntegrations & { figma: TextItemFigmaIntegration };
} {
  if (
    textItem.integrations &&
    !!textItem.integrations.figma &&
    (textItem.integrations.figma as any).position &&
    !!textItem.figma_node_ID
  )
    return true;
  return false;
};

// Helpers for Groups

export const isLinkedGroup = function isLinkedGroup<T extends BaseGroup>(
  group: T
): group is T & {
  integrations: GroupIntegrations & { figma: GroupFigmaIntegration };
} {
  if ((group.integrations.figma as any).frame_id) return true;
  return false;
};

// Helpers for Projects

export const isLinkedProject = function isLinkedLProject<T extends BaseProject>(
  project: T
): project is T & {
  integrations: DocumentIntregrations & { figma: DocumentFigmaIntegration };
} {
  if ((project.integrations.figma as any).file_id) return true;
  return false;
};

// Project Summaries

export const ZProgressSegment = z.object({
  color: z.string(),
  percentage: z.number(),
});

export const ZProjectSummary = z.object({
  _id: ZObjectId,
  name: z.string(),
  folder: z
    .object({
      _id: ZObjectId,
      name: z.string(),
    })
    .optional(),
  componentProgress: z.object({
    segments: z.array(ZProgressSegment),
    totalPercentageColor: z.string().optional(),
    percentageDisplayed: z.number().optional(),
  }),
  statusProgress: z.object({
    segments: z.array(ZProgressSegment),
    totalPercentageColor: z.string().optional(),
    percentageDisplayed: z.number().optional(),
  }),
  branchId: z.string().nullable().optional(),
  commentCount: z.number(),
  invitedAt: z.date().optional(),
  invitedBy: z.string().optional(),
  updatedAt: z.date(),
  edited_at: z.date(),
  edited_by: z.string().nullable().optional(),
  isSample: z.boolean(),
  isNorthStar: z.boolean().optional(),
});

export type IBProjectSummary = z.infer<typeof ZProjectSummary>;
export type IFProjectSummary = FrontendSchema<IBProjectSummary>;

export const ACTIVATION_PROGRESS_SEGMENT_COLORS = {
  WIP: "#FF6F50",
  REVIEW: "#FFD850",
  FINAL: "#63D71D",
  FINAL_PERCENTAGE: "#418916",
};
