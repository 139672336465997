import {
  blockFamilyAtom,
  flattenedProjectItemsAtom,
  INavBlockItem,
  INavTextItem,
  isValidBlock,
  textItemFamilyAtom,
} from "@/stores/Project";
import { clearSelectionActionAtom, selectedBlockIdsAtom, selectedTextItemIdsAtom } from "@/stores/ProjectSelection";
import NavItem from "@ds/molecules/NavigatorRow";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense } from "react";
import style from "./style.module.css";

function LeftSidebar() {
  // TODO: Implement Selection
  // TODO: Implement Reordering
  // TODO: Implement Loading State
  const flattedProjectItems = useAtomValue(flattenedProjectItemsAtom);

  return (
    <div className={style.leftSidebarContainer}>
      {flattedProjectItems.map((item) => (
        <Suspense key={item._id} fallback={<div>Loading...</div>}>
          {item.type === "block" && <BlockNavItem item={item} />}
          {item.type === "text" && <TextNavItem item={item} />}
        </Suspense>
      ))}
    </div>
  );
}

function BlockNavItem(props: { item: INavBlockItem }) {
  const block = useAtomValue(blockFamilyAtom(props.item._id));
  const setSelectedBlockIds = useSetAtom(selectedBlockIdsAtom);
  const clearSelectionAction = useSetAtom(clearSelectionActionAtom);

  if (!isValidBlock(block)) return <></>;

  return (
    <NavItem
      key={block._id}
      className={style.navItem}
      type="block"
      pressed={false}
      onChange={(pressed) => {
        if (pressed) {
          clearSelectionAction();
          setSelectedBlockIds([block._id!]);
        }
      }}
    >
      {block.name || "Untitled Block"}
    </NavItem>
  );
}

function TextNavItem(props: { item: INavTextItem }) {
  const textItem = useAtomValue(textItemFamilyAtom(props.item._id));
  const setSelectedTextItemIds = useSetAtom(selectedTextItemIdsAtom);
  const clearSelectionAction = useSetAtom(clearSelectionActionAtom);

  const itemType = textItem.blockId ? "block-child" : "default";

  return (
    <NavItem
      key={textItem._id}
      className={style.navItem}
      type={itemType}
      pressed={false}
      onChange={(pressed) => {
        if (pressed) {
          clearSelectionAction();
          setSelectedTextItemIds([textItem._id]);
        }
      }}
    >
      {textItem.text}
    </NavItem>
  );
}

export default LeftSidebar;
