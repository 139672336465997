import { getProjectActivity } from "@/http/changesTyped";
import { getTextItemActivity } from "@/http/comp";
import { projectIdAtom } from "@/stores/Project";
import { selectedTextItemIdsAtom } from "@/stores/ProjectSelection";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createSelectedActivityAtom,
  fetchMoreLibraryActivityAtom,
  libraryActivityAtom,
  selectedActivityAtomsAtom,
} from "../shared/stores/ActivityStore";
import FilterBar from "./components/FilterBar";
import NavBar from "./components/NavBar";
import ProjectContent from "./components/ProjectContent";
import { WebsocketsHandler } from "./components/WebsocketsHandler";
import style from "./index.module.css";

function ProjectPage() {
  const params = useParams<{ id: string }>();
  const [projectId, setProjectId] = useAtom(projectIdAtom);

  useEffect(function initProjectIdAtom() {
    const projectId = params.id;
    if (!projectId) {
      throw new Error("Project ID in URL is required");
    }
    setProjectId(projectId);
  }, []);

  return (
    <div className={style.wrapper}>
      {projectId && (
        <>
          <WebsocketsHandler projectId={projectId} />
          {/* <ActivityHandler /> */}
          <NavBar />
          <FilterBar />
          <ProjectContent />
        </>
      )}
      {/*<div style={{ display: "flex", justifyContent: "space-between" }}>
        <LibraryNav hardCodedItem="66ccdd729b36f0c82fdadf99" />
        <TextList />
        <EditPanel libraryActivity={<ProjectActivity />} selectedActivity={<TextItemActivity />} />
      </div> */}
    </div>
  );
}

export const createGetProjectLibraryActivityFunction =
  (projectId: string) => async (args: { skip: number; limit: number }) => {
    const [request] = getProjectActivity({
      projectId,
      skip: args.skip,
      limit: args.limit,
    });

    const result = await request;

    return result.data.info;
  };

function ProjectActivity() {
  const projectId = useAtomValue(projectIdAtom);
  const projectActivity = useAtomValue(libraryActivityAtom);
  const [isFetchingProjectActivity, setIsFetchingProjectActivity] = useState(false);
  const fetchMoreLibraryActivity = useSetAtom(fetchMoreLibraryActivityAtom);
  const fetchMoreProjectActivity = () =>
    fetchMoreLibraryActivity(createGetProjectLibraryActivityFunction(projectId || ""));

  useEffect(() => {
    if (projectId) {
      fetchMoreProjectActivity();
    }
  }, [projectId]);

  return (
    <>
      <button
        onClick={async () => {
          setIsFetchingProjectActivity(true);
          await fetchMoreProjectActivity();
          setIsFetchingProjectActivity(false);
        }}
        disabled={isFetchingProjectActivity}
      >
        Load more activity
      </button>
      {(projectActivity.activity || []).map((activity) => (
        <div key={activity._id}>
          <h3>{activity.entry_type}</h3>
          <p>{activity.user}</p>
        </div>
      ))}
    </>
  );
}

export const createGetTextItemActivityFunction = (textItemId: string) => async () => {
  // TODO: Remove for production
  if (textItemId.length === 0) {
    return [];
  }

  const [request] = getTextItemActivity({
    textItemId: textItemId,
  });

  const result = await request;

  return result.data;
};

function TextItemActivity() {
  const selectedTextItems = useAtomValue(selectedTextItemIdsAtom);
  const [selectedActivityAtoms, setSelectedActivityAtoms] = useAtom(selectedActivityAtomsAtom);

  useEffect(() => {
    if (selectedTextItems.length === 1) {
      setSelectedActivityAtoms((prev) => {
        // If we wanted data to load on selection we can just remove the !selectedActivityAtoms[selectedIds[0]] check
        if (prev[selectedTextItems[0]]) {
          return prev;
        }

        return {
          ...prev,
          [selectedTextItems[0]]: createSelectedActivityAtom(
            selectedTextItems[0],
            createGetTextItemActivityFunction(selectedTextItems[0])
          ),
        };
      });
    }
  }, [selectedTextItems]);

  const singleSelectedId = selectedTextItems.length === 1 ? selectedTextItems[0] : "invalid";
  const textItemActivity = useAtomValue(
    (selectedActivityAtoms[singleSelectedId] || selectedActivityAtoms["invalid"]).activity
  );

  if (selectedTextItems.length !== 1) {
    return <p>Select a single text item to view activity</p>;
  }

  return (
    <>
      {textItemActivity.map((activity) => (
        <div key={activity._id}>
          <h3>{activity.entry_type}</h3>
          <p>{activity.user}</p>
        </div>
      ))}
    </>
  );
}

export { ProjectPage };
