import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;

  /**
   * Styling types. 'code' used for dev elements, like API IDs.
   */
  type?: "default" | "code";

  /**
   * Variants for badge shape. Round is pill-shaped, rounded is slightly rounded corners.
   */
  variant?: "round" | "rounded";

  /**
   * Color of the badge. Default is primary.
   */
  color?: "primary" | "secondary" | "green" | "purple" | "danger" | "invert" | "empty";

  /**
   * Size of the badge. defaults to md.
   */
  size?: "xs" | "sm" | "md" | "lg";
}

export function Badge(props: IProps) {
  const color = props.color ?? "primary";
  const variant = props.variant ?? "rounded";
  const type = props.type ?? "default";
  const size = props.size ?? "md";

  return (
    <div
      style={props.style}
      className={classNames(
        style.BadgeWrapper,
        {
          [style[`color-${color}`]]: true,
          [style[`variant-${variant}`]]: true,
          [style[`type-${type}`]]: true,
          [style[`size-${props.size}`]]: props.size,
        },
        props.className
      )}
      data-testid="badge"
    >
      {props.children}
    </div>
  );
}

export default Badge;
