import * as httpDittoProject from "@/http/dittoProject";
import batchedAsyncAtomFamily, { REFRESH, REFRESH_SILENTLY } from "@shared/frontend/stores/batchedAsyncAtomFamily";
import { IFDittoProjectData } from "@shared/types/http/DittoProject";
import { atom } from "jotai";
import { splitAtom, unwrap } from "jotai/utils";

// MARK: - Source Atoms

/**
 * The source of truth for the project Id. This atom should be set when the user
 * navigates to a project.
 */
export const projectIdAtom = atom<string | null>(null);

export const textItemFamilyAtom = batchedAsyncAtomFamily({
  asyncFetchRequest: async (get, ids) => {
    const [request] = httpDittoProject.getTextItems({
      ids,
      projectId: get(projectIdAtom)!,
    });
    const response = await request;

    return response.data;
  },
  getId: (item) => item._id,
  debugPrefix: "Text Item",
});

/**
 * The source of truth for blocks. You should be fetching/updating blocks
 * through this atom to ensure that the UI updates correctly.
 *
 * This atom should be consumed like a mix between a Jotai [FamilyAtom](https://jotai.org/docs/utilities/family) and [AtomWithReset](https://jotai.org/docs/utilities/resettable#atomwithreset).
 */
export const blockFamilyAtom = batchedAsyncAtomFamily({
  asyncFetchRequest: async (get, ids) => {
    const [request] = httpDittoProject.getBlocks({
      ids,
      projectId: get(projectIdAtom)!,
    });
    const response = await request;

    return response.data;
  },
  getId: (item) => item._id,
  debugPrefix: "Block",
});

/**
 * Fetches a project by its Id.
 */
async function fetchProjectById(projectId: string) {
  const [request] = httpDittoProject.getProject({ projectId });
  const { data: project } = await request;
  return project;
}

export const projectAtom = atom(
  async (get) => {
    const projectId = get(projectIdAtom);
    if (!projectId) throw new Error("projectIdAtom is not set");
    return await fetchProjectById(projectId);
  },
  async (
    get,
    set,
    newValue:
      | Promise<IFDittoProjectData>
      | IFDittoProjectData
      | ((
          previousValue: Promise<IFDittoProjectData> | IFDittoProjectData
        ) => Promise<IFDittoProjectData> | IFDittoProjectData)
      | typeof REFRESH
      | typeof REFRESH_SILENTLY
  ) => {
    if (newValue === REFRESH) {
      const projectId = get(projectIdAtom);
      if (!projectId) throw new Error("projectIdAtom is not set");
      set(projectAtom, fetchProjectById(projectId));
    } else if (newValue === REFRESH_SILENTLY) {
      const projectId = get(projectIdAtom);
      if (!projectId) throw new Error("projectIdAtom is not set");
      fetchProjectById(projectId).then((project) => set(projectAtom, project));
    } else if (newValue instanceof Function) {
      set(projectAtom, newValue(await get(projectAtom)));
    } else {
      set(projectAtom, newValue);
    }
  }
);

// MARK: - Derived Atoms

export const projectNameAtom = atom(async (get) => {
  const project = await get(projectAtom);
  return project.name;
});

export const projectBlocksAtom = atom(async (get) => {
  const project = await get(projectAtom);
  return project.blocks;
});

export const projectBlocksSplitAtom = splitAtom(unwrap(projectBlocksAtom, (prev) => prev ?? []));

export const projectTextItemsCountAtom = atom((get) => {
  const blocks = get(unwrap(projectBlocksAtom, (prev) => prev ?? []));
  return blocks.reduce((acc, block) => acc + block.textItems.length, 0);
});
export interface INavBlockItem {
  _id: string;
  type: "block";
}

export interface INavTextItem {
  _id: string;
  type: "text";
  sortKey: string;
}

export function isValidBlock(block: any): block is INavBlockItem {
  return block && block._id;
}

export const flattenedProjectItemsAtom = atom(async (get) => {
  const project = await get(projectAtom);
  const itemsInBlocks = project.blocks.reduce<(INavBlockItem | INavTextItem)[]>((acc, block) => {
    if (isValidBlock(block)) {
      acc.push({ _id: block._id, type: "block" });
      acc.push(...block.textItems.map((textItem) => ({ ...textItem, type: "text" as const })));
    } else {
      acc.push(...block.textItems.map((textItem) => ({ ...textItem, type: "text" as const })));
    }
    return acc;
  }, []);

  return itemsInBlocks;
});
