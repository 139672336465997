import { clearSelectionActionAtom, selectedBlockIdsAtom, selectedTextItemIdsAtom } from "@/stores/ProjectSelection";
import { useSetAtom } from "jotai";
import { Suspense, useEffect } from "react";
import DetailsPanel from "../DetailsPanel";
import LeftSidebar from "../LeftSidebar";
import TextItemList from "../TextItemList";
import style from "./style.module.css";

function ProjectContent() {
  const setSelectedTextItemIds = useSetAtom(selectedTextItemIdsAtom);
  const setSelectedBlockIds = useSetAtom(selectedBlockIdsAtom);
  const clearSelectionAction = useSetAtom(clearSelectionActionAtom);

  useEffect(function handleEscape() {
    const keyboardEventListener = function (event: KeyboardEvent) {
      if (event.key === "Escape") {
        clearSelectionAction();
      }
    };

    document.addEventListener("keydown", keyboardEventListener);

    return function cleanup() {
      document.removeEventListener("keydown", keyboardEventListener);
    };
  }, []);

  return (
    <div className={style.projectContainer}>
      <Suspense fallback={<div>Loading...</div>}>
        <LeftSidebar />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <TextItemList />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <DetailsPanel />
      </Suspense>
    </div>
  );
}

export default ProjectContent;
