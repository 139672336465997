import ProjectTabBar from "../ProjectTabBar";

function ProjectDetailsPanel() {
  return (
    <>
      <ProjectTabBar />
    </>
  );
}

export default ProjectDetailsPanel;
