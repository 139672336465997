// Detail panel of the Component Library

import classnames from "classnames";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useWorkspace } from "@/store/workspaceContext";
import { VARIANT_STATUSES_ENABLED } from "@/utils/featureFlags";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDownWardIcon from "@mui/icons-material/ArrowDownward";
import DescriptionIcon from "@mui/icons-material/Description";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ReorderIcon from "@mui/icons-material/Reorder";
import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import * as DittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import ReactTooltip from "react-tooltip";
import useWebSocket from "react-use-websocket";
import AutoAwesomeIcon from "../../../shared/frontend/AutoAwesomeIcon";
import RoundedCount from "../../components/rounded-count/rounded-count";
import { COMPONENT_LIBRARY_PANEL_STATES, PANELS, routes } from "../../defs";
import http, { API } from "../../http/index";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import { INTERCOM_ATTR } from "../../utils/constants";
import { WorkspaceComponentContext } from "../../views/Components";
import ErrorBoundary from "../ErrorBoundary";
import NotifIndicator from "../NotifIndicator";
import PluralsBadge from "../PluralsBadge";
import StatusSelect from "../StatusSelect";
import RichTextVariableInput from "../VariableTextArea/RichTextVariableInput";
import ActivityColumn from "../activity-column/index";
import CommentEditorEditPanel from "../comment-editor/CommentEditorEditPanel";
import CompHistory from "../comphistory/comphistory";
import ComponentEditVariantModal from "../component-edit-variant-modal";
import { ComponentMergeSuggestionPanel } from "../component-merge-suggestions/ComponentMergeSuggestionPanel";
import ComponentNewVariantModal from "../component-new-variant-modal";
import EditWSComp from "../editwscomp/editwscomp";
import spinner from "./../../assets/small-spinner.gif";
import style from "./style.module.css";

const CompDetail = ({
  handleChangeItemClick,
  displayApiIds,
  selectedId,
  selectComp,
  selectedComp,
  selectedFolder,
  selectedCompName,
  selectedWsComp,
  allComponentsCache,
  fetchAllComps,
  setSelectedId,
  updateCompResultComments,
  commentState,
  setCommentState,
  setPanelState,
  panelState,
  compLibraryHistory,
  loadingHistory,
  allHistoryFetched,
  fetchLibraryHistoryNewPage,
  refreshLibraryHistory,
  setShowCompError,
  unselectAll,
  setLoadingHistory,
  tagSuggestions,
  fetchWorkspaceTags,
  handleMoveToFolder,
  deleteComp,
  detachAllAndDelete,
  mergeComponents,
  componentMergeSuggestionState,
  instanceDocs,
  fetchInstances,
  componentPostSaveCallback,
  componentPostRenameCallback,
}) => {
  const { users: workspaceUsers } = useWorkspace();
  const workspaceComponentContext = useContext(WorkspaceComponentContext);
  const {
    handleSingleSelect,
    selectionState,
    quickReplyCommentState: [quickReplyCommentState, setQuickReplyCommentState],
    updateComponentsInMemory,
  } = workspaceComponentContext;

  const onSaveComment = async (commentText, mentionedUsers, resetEditorState) => {
    const { url, body } = API.comments.post.postCompThread;
    let requestBody = body({
      first_comment: commentText,
      figma_node_id: null,
      mentionedUserIds: mentionedUsers.map((user) => user.id),
      ws_comp_id: selectedId,
      from: "web_app",
    });
    await http.post(url, requestBody);
    resetEditorState();
  };

  // This function is solely in charge of updating the text of the text item and
  // updating the text item in state. Resolution of the comment thread is handled
  // internally by the <CommentThread /> component.
  const onResolveSuggestion = async (isAccepted, _threadId, suggestionData, { wsCompId }) => {
    if (!isAccepted) {
      return;
    }

    const { url, body } = API.ws_comp.post.edit;
    await http.post(
      url,
      body({
        ws_comp_id: wsCompId,
        doc_id: null,
        newCompInfo: {
          text: suggestionData.text_after,
          rich_text: suggestionData.rich_text_after,
          variables: suggestionData.variables,
        },
        from: "web_app",
      })
    );

    updateComponentsInMemory([
      {
        _id: selectedId,
        text: suggestionData.text_after,
        rich_text: suggestionData.rich_text_after,
        variables: suggestionData.variables,
      },
    ]);
  };

  const { lastMessage } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });
  const history = useHistory();
  const location = useLocation();
  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);
  const [compHistory, setCompHistory] = useState([]);
  const unresolvedCommentThreads = useMemo(
    () => compHistory.filter((ch) => ch.comments && !ch.is_resolved),
    [compHistory]
  );
  const [compHistoryLoading, setCompHistoryLoading] = useState(false);
  const [compVariants, setCompVariants] = useState([]);
  const [showEditVariantModal, setShowEditVariantModal] = useState(false);
  const [showAddNewVariantModal, setShowAddNewVariantModal] = useState(false);
  const [selectedModalVariant, setSelectedModalVariant] = useState({});
  const [allVariants, setAllVariants] = useState({
    inUse: [],
    notInUse: [],
  });
  const [variantsLoading, setVariantsLoading] = useState(true);
  const variantNamesByVariantId = useMemo(
    () =>
      allVariants.inUse
        ? allVariants.inUse.reduce((acc, variant) => ({ ...acc, [variant._id]: variant.name }), {})
        : {},
    [allVariants]
  );

  const isEditEnabled = userHasResourcePermission("component_folder:edit");

  const fetchData = async () => {
    setCompHistoryLoading(true);
    fetchHistory();
    fetchInstances();

    if (panelState === PANELS.comp_library.none) {
      let { state } = location;
      if (state && state.panelState) {
        const panelState = state.panelState;
        if (panelState) {
          setPanelState(panelState);
          return;
        }
      }

      if (commentState.thread_id) {
        setPanelState(PANELS.comp_library.activity);
        return;
      }
      setPanelState(PANELS.comp_library.edit);
    }
  };

  const totalInstanceCount = useMemo(() => {
    return Array.from(instanceDocs).reduce((acc, [_id, doc]) => {
      const numDocInstances = Array.from(doc.compInstances).reduce((acc, [_, instances]) => acc + instances.length, 0);
      return acc + numDocInstances;
    }, 0);
  }, [instanceDocs]);

  const fetchHistory = async () => {
    try {
      const { url } = API.changes.get.ws_comp;
      const { data: changes } = await http.get(url(selectedId));
      setCompHistory(changes || []);
      setCompHistoryLoading(false);
      refreshLibraryHistory();
    } catch (e) {
      setCompHistoryLoading(false);
      console.info("in compdetail.jsx: ", e.message);
    }
  };

  const getAllVariants = async () => {
    try {
      const { url } = API.ws_comp.get.variants;
      const { data: variants } = await http.get(url(selectedWsComp._id));

      setAllVariants(variants);
      setVariantsLoading(false);
    } catch (err) {
      setVariantsLoading(false);
      console.error("error fetching variants in compdetail.jsx: ", err);
    }
  };

  /**
   * When "Go to component" in the activity panel is clicked, enable quick
   * reply comments.
   */
  const handleCommentChangeClick = (_componentId, threadId) => {
    setQuickReplyCommentState({
      enabled: true,
      initialThreadId: threadId,
      goBackState: { panelState },
    });
  };

  useEffect(() => {
    if (!lastMessage) return;
    const data = JSON.parse(lastMessage.data);

    if (data.messageType === WEBSOCKET_EVENTS.UPSERT_WS_COMPS_COMMENT && selectedId === data.componentId) {
      setCompHistory((previousHistory) => {
        const index = previousHistory.findIndex((historyItem) => historyItem._id === data.commentThread._id);
        if (index >= 0) previousHistory[index] = data.commentThread;
        else previousHistory.unshift(data.commentThread);
        return [...previousHistory];
      });
    }
  }, [lastMessage]);

  useEffect(() => {
    if (selectedWsComp && selectedWsComp._id) {
      setCompVariants(selectedWsComp.variants);
      getAllVariants();
    }
  }, [selectedWsComp]);

  useEffect(() => {
    if (selectedId) {
      fetchData();
    } else {
      setPanelState((state) => (state === PANELS.comp_library.merge_suggestions ? state : PANELS.comp_library.none));
    }
  }, [selectedId]);

  useDittoEventListener(
    DittoEvents.componentsUpdated,
    function onComponentsUpdated(data) {
      for (const component of data.components) {
        if (component._id === selectedId) {
          fetchData();
          break;
        }
      }
    },
    []
  );

  const goToDoc = (id) => {
    history.push(routes.nonNavRoutes.project.getPath(id));
  };

  const goToDocPage = (projectId, pageId) => {
    history.push(routes.nonNavRoutes.projectPage.getPath(projectId, pageId));
  };

  const goToDocComponent = (projectId, pageId, instanceId) => {
    history.push(routes.nonNavRoutes.projectPageInstance.getPath(projectId, pageId, instanceId));
  };

  const editVariantModal = (compVariant) => {
    setSelectedModalVariant(compVariant);
    setShowEditVariantModal(true);
  };

  const updateHistory = async (updateCompHist = true) => {
    if (updateCompHist) {
      setCompHistoryLoading(true);
      await fetchHistory();
      setCompHistoryLoading(false);
    }
  };

  // This is necessary to avoid losing state when the edit panel unmounts
  // when switching between editcomp / editwscomp or switching between
  // tabs.
  const controlledQuickReplyState = useState({ loaded: false });
  const [, setControlledQuickReplyState] = controlledQuickReplyState;
  useEffect(() => {
    setControlledQuickReplyState({ loaded: false });
  }, [quickReplyCommentState, setControlledQuickReplyState]);

  const commentEditor = (
    <CommentEditorEditPanel
      className={style.commentEditorEditPanel}
      quickReplyMode={quickReplyCommentState}
      quickReplyState={controlledQuickReplyState}
      onQuickReplyNavigation={(commentThread) => {
        if (selectionState.selectedId !== commentThread.ws_comp_id) {
          handleSingleSelect(commentThread.ws_comp_id, {
            disableQuickReplyComments: false,
          });
        }
      }}
      onQuickReplyBackArrowClick={() => {
        setQuickReplyCommentState({ enabled: false });
        unselectAll();
      }}
      onSeeAllActivity={() => setPanelState(PANELS.comp_library.activity)}
      latestCommentThread={unresolvedCommentThreads[0]}
      loadCommentThreads={async (selectedThreadId) => {
        const { url } = API.comments.get.unresolvedComponentLibraryComments;
        const { data: commentThreads } = await http.get(url, {
          params: { selectedThreadId },
        });
        return commentThreads;
      }}
      commentThreadFunctions={{
        forceShowToast: () => null,
        handleHistoryUpdate: updateHistory,
        handleInlineReplyClick: (_threadId) => null,
        handleScrollTo: (_textItemId) => null,
        handleDocUpdateParent: () => null,
        handleCommentChangeClick: () => null,
        updateCommentThread: () => null,
        fetchCompInfo: () => null,
        onAcceptSuggestion: (...args) => onResolveSuggestion(true, ...args),
        onRejectSuggestion: (...args) => onResolveSuggestion(false, ...args),
      }}
      commentEditor={{
        placeholderText: "Leave a comment or @mention",
        saveContentCallback: onSaveComment,
      }}
      onExitQuickReplyMode={() => setQuickReplyCommentState({ enabled: false })}
      possibleMentions={workspaceUsers}
      isCommentingDisabled={false}
    />
  );

  const onSaveSuggestion = async ({ text, richText, variables }) => {
    const { url, body } = API.comments.post.postCompThread;
    await http.post(
      url,
      body({
        suggestion: {
          text_before: selectedComp.text,
          text_after: text,
          rich_text_before: selectedComp.rich_text,
          rich_text_after: richText,
          variables,
        },
        mentionedUserIds: [],
        ws_comp_id: selectedId,
        from: "web_app",
      })
    );
  };

  const numComments = selectedComp?.comment_threads?.reduce(
    (acc, thread) =>
      // Sometimes comment threads appear to be getting returned as strings
      // rather than populated objects, causing crashes for customers,
      // but it isn't reproducible; this check ensures crashing doesn't occur
      // on the rare occasion that the problem pops up
      acc + typeof thread === "object" ? thread.comments?.length ?? 0 : 0,
    0
  );

  const showMergeSuggestionsNotifIndicator = componentMergeSuggestionState.suggestions?.some((s) => !s.ignored);

  return (
    <div className={style.sidebar}>
      <div>
        {panelState in COMPONENT_LIBRARY_PANEL_STATES.library && (
          <div
            className={classnames({
              [style.titleSection]: true,
            })}
          >
            <div
              onClick={() => setPanelState(PANELS.comp_library.none)}
              className={classnames({
                [style.option]: true,
                [style.selected]: panelState === PANELS.comp_library.none,
              })}
            >
              <ReorderIcon className={style.tabIcon} />
              Activity
            </div>
            <div
              onClick={() => {
                componentMergeSuggestionState.regenerateSuggestions();
                setPanelState(PANELS.comp_library.merge_suggestions);
              }}
              className={classnames({
                [style.option]: true,
                [style.selected]: panelState === PANELS.comp_library.merge_suggestions,
              })}
            >
              <div className={style.suggestionsTab}>
                {showMergeSuggestionsNotifIndicator > 0 && <NotifIndicator />}
                <AutoAwesomeIcon className={style.tabIcon} />
                Suggestions
                <RoundedCount
                  count={componentMergeSuggestionState.suggestions?.length}
                  className={style.suggestionsCount}
                />
              </div>
            </div>
          </div>
        )}
        {panelState.toLowerCase() in COMPONENT_LIBRARY_PANEL_STATES.component && (
          <div
            className={classnames({
              [style.titleSection]: true,
            })}
          >
            <div
              className={classnames({
                [style.option]: true,
                [style.selected]: panelState === PANELS.comp_library.edit,
              })}
              onClick={() => {
                checkDetailPanelChanges(() => {
                  setPanelState(PANELS.comp_library.edit);
                });
              }}
            >
              Edit
            </div>
            <div
              className={classnames({
                [style.option]: true,
                [style.selected]: panelState === PANELS.comp_library.instances,
              })}
              onClick={() => {
                checkDetailPanelChanges(() => {
                  setPanelState(PANELS.comp_library.instances);
                });
              }}
              data-tour={INTERCOM_ATTR.COMPONENTS_INSTANCES_TAB_LINK}
            >
              Instances
              {totalInstanceCount > 0 && <RoundedCount className={style.count} count={totalInstanceCount} />}
            </div>

            <div
              className={classnames({
                [style.option]: true,
                [style.selected]: panelState === PANELS.comp_library.activity,
              })}
              onClick={() => {
                checkDetailPanelChanges(() => {
                  setPanelState(PANELS.comp_library.activity);
                });
              }}
            >
              Activity
              {numComments > 0 && (
                <div className={style.commentCount}>
                  <ModeCommentIcon className={style.icon} />
                  {numComments}
                </div>
              )}
            </div>
            <div
              className={classnames({
                [style.option]: true,
                [style.selected]: panelState === PANELS.comp_library.variants,
              })}
              onClick={() => {
                checkDetailPanelChanges(() => {
                  setPanelState(PANELS.comp_library.variants);
                });
              }}
              data-testid="component-library-variants-tab"
            >
              Variants
              {selectedWsComp?.variants?.length > 0 && (
                <RoundedCount className={style.count} count={selectedWsComp.variants.length} />
              )}
            </div>
          </div>
        )}
      </div>

      {showEditVariantModal && (
        <ComponentEditVariantModal
          ws_comp={selectedWsComp}
          variant={selectedModalVariant}
          onHide={() => setShowEditVariantModal(false)}
          handleHistoryUpdate={updateHistory}
          setSelectedId={setSelectedId}
          fetchAllComps={fetchAllComps}
        />
      )}

      {showAddNewVariantModal && selectedWsComp?.instances && (
        <ComponentNewVariantModal
          ws_comp={selectedWsComp}
          variant={selectedModalVariant}
          onHide={() => setShowAddNewVariantModal(false)}
          setSelectedId={setSelectedId}
          availableVariants={allVariants.notInUse}
          getAllVariants={getAllVariants}
          fetchAllComps={fetchAllComps}
          refreshLibraryHistory={refreshLibraryHistory}
          isInSampleFolder={selectedFolder?.isSample}
        />
      )}

      {selectedComp && selectedId && panelState == PANELS.comp_library.edit && (
        <ErrorBoundary componentName="EditWSComp" type="Selection">
          <EditWSComp
            key={selectedId}
            postSaveCallback={componentPostSaveCallback}
            postRenameCallback={componentPostRenameCallback}
            onSaveSuggestion={onSaveSuggestion}
            commentEditor={commentEditor}
            handleHistoryUpdate={updateHistory}
            allComponentsCache={allComponentsCache}
            isDraftComp={selectedWsComp.isDraft}
            selectedId={selectedId}
            selectedApiID={selectedWsComp.apiID}
            componentType={selectedWsComp.type}
            selectedCompName={selectedCompName}
            fetchAllComps={fetchAllComps}
            origComp={selectedComp}
            tagSuggestions={tagSuggestions}
            getWorkspaceTags={fetchWorkspaceTags}
            fetchHistory={fetchHistory}
            setPanelState={setPanelState}
            refreshLibraryHistory={refreshLibraryHistory}
            handleMoveToFolder={handleMoveToFolder}
            deleteComp={deleteComp}
            detachAllAndDelete={detachAllAndDelete}
            mergeComponents={mergeComponents}
          />
        </ErrorBoundary>
      )}

      {selectedComp && selectedId && panelState == PANELS.comp_library.instances && (
        <div className={style.instances}>
          {selectedWsComp.isDraft && <div className={style.draft}>Component not currently attached in a project.</div>}
          {!selectedWsComp.isDraft &&
            Array.from(instanceDocs).map(([id, doc], index) => {
              return (
                <React.Fragment key={index}>
                  <div className={style.docLink} onClick={() => goToDoc(id)}>
                    <div className={style.docName}>
                      {doc.doc_name}
                      {doc.isSample && <div className={style.sampleTag}>Sample</div>}
                    </div>
                    <RoundedCount
                      count={Array.from(doc.compInstances).reduce((acc, [_, instances]) => acc + instances.length, 0)}
                    />
                  </div>
                  {Array.from(doc.compInstances).map(([pageName, instances], pageIdx) => (
                    <React.Fragment key={pageIdx}>
                      <div
                        className={style.pageHeading}
                        onClick={instances.length ? () => goToDocPage(id, instances[0].page_id) : undefined}
                      >
                        <div className={style.pageLabel}>
                          <DescriptionIcon className={style.icon} />
                          <div className={style.pageName}>{pageName}</div>
                        </div>
                        <RoundedCount count={instances.length} />
                      </div>
                      {instances.map((instance, instanceIdx) => (
                        <div
                          className={style.componentInstance}
                          onClick={() => goToDocComponent(id, instance.page_id, instance._id)}
                          key={instanceIdx}
                        >
                          <span className={style.groupName}>
                            {instance.group_name + (instance.block_name ? ` - ${instance.block_name}` : "")}
                          </span>
                          <span className={style.componentName}>{selectedWsComp.name}</span>
                        </div>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              );
            })}
        </div>
      )}
      {selectedId && panelState == PANELS.comp_library.activity && (
        <CompHistory
          onResolveSuggestion={onResolveSuggestion}
          isWsComp={true}
          setPanelState={setPanelState}
          selectedId={selectedId}
          origComp={selectedComp}
          compHistoryLoading={compHistoryLoading}
          history={compHistory}
          workspaceUsers={workspaceUsers}
          handleHistoryUpdate={updateHistory}
          commentStateThreadId={commentState.thread_id}
          fetchCompInfo={() => {
            // blank function because CompHistory is used
            // in Doc as well, and will break if left empty
            // in CompDetail component
          }}
          setCompHistory={setCompHistory}
          setCommentState={setCommentState}
          handleDocUpdate={() => {
            // blank function because CompHistory is used
            // in Doc as well, and will break if left empty
            // in CompDetail component
          }}
          updateCompResultComments={updateCompResultComments}
          fetchAllComps={fetchAllComps}
          refreshLibraryHistory={refreshLibraryHistory}
          isCommentingDisabled={false}
          setSyncSettingsModalVisible={undefined}
        />
      )}
      {selectedId && panelState == PANELS.comp_library.variants && (
        <div
          className={classnames([
            style.flexColumn,
            style.containerPadding,
            style.overflowContainer,
            style.variantColumn,
          ])}
        >
          <div>
            {variantsLoading && (
              <div className={classnames([style.flexCentered, style.maxWidth, style.paddingVertical])}>
                <img className={classnames([style.loading])} src={spinner} />
              </div>
            )}
            {!variantsLoading && (
              <div className={style.flexBetween}>
                <div className={classnames(style.sectionHeader)}>
                  {compVariants.filter(({ variantId }) => !!variantNamesByVariantId[variantId]).length || "No"} Variant
                  {compVariants.length !== 1 && "s"}
                </div>
                {isEditEnabled && (
                  <div
                    className={classnames([style.cursorPointer, style.flexCentered, style.textMuted])}
                    onClick={() => {
                      if (!selectedWsComp.instances) {
                        return;
                      }
                      setShowAddNewVariantModal(true);
                    }}
                  >
                    <AddCircleIcon className={classnames([style.iconSmall, style.paddingRightSmall])} />
                    <ReactTooltip id="variants" place="bottom" effect="solid">
                      Add Variant
                    </ReactTooltip>
                    <span className={classnames(style.smallerFontSize)}>Add variant</span>
                  </div>
                )}
              </div>
            )}
          </div>
          {!variantsLoading && (
            <div className={classnames([style.marginTopSmall])}>
              {compVariants.length === 0 && (
                <div className={style.variantsEmptyState}>
                  This component doesn&apos;t have any variants. To learn more about variants for components, click{" "}
                  <a href="https://www.dittowords.com/docs/variants-for-components" target="_blank" rel="noreferrer">
                    here
                  </a>
                  .
                </div>
              )}
              {compVariants.map((compVariant) => {
                const variantName = variantNamesByVariantId[compVariant.variantId];
                if (!variantName) {
                  return <React.Fragment key={compVariant.variantId} />;
                }

                return (
                  <div key={compVariant.variantId} className={style.compVariant}>
                    <div className={classnames([style.variantTitleBar])}>
                      <div className={classnames([style.flexBetween, style.marginBottom])}>
                        <div className={style.variantLabel}>
                          <span className={classnames([style.variantName])}>{variantName}</span>
                          <PluralsBadge comp={selectedComp} variantId={compVariant.variantId} />
                        </div>
                        {isEditEnabled && (
                          <div
                            data-testid="edit-variant-button"
                            className={classnames("text-muted", [style.editVariant, style.cursorPointer])}
                            onClick={() => {
                              editVariantModal({
                                ...compVariant,
                                variantName,
                              });
                            }}
                          >
                            Edit
                          </div>
                        )}
                      </div>
                      <RichTextVariableInput
                        placeholder={"No variant text."}
                        isDisabled={true}
                        contentLength={0}
                        setContentLength={() => {}}
                        setCurrentSelection={() => {}}
                        initialValues={compVariant}
                        handleTextChange={() => {}}
                        disableRichText={false}
                        showVariableModal={() => {}}
                      />
                      {VARIANT_STATUSES_ENABLED && (
                        <StatusSelect status={compVariant.status || "NONE"} handleStatusChange={() => {}} disabled />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      {(panelState === PANELS.comp_library.none || panelState === PANELS.comp_library.inline_reply) && (
        <div className={style.overflowContainer}>
          {loadingHistory ? (
            <div>
              <div className={style.loading}></div>
              <div className={style.loadMore}>
                <div>Loading</div>
                <img src={spinner} />
              </div>
            </div>
          ) : (
            <ActivityColumn
              onResolveSuggestion={onResolveSuggestion}
              setPanelState={setPanelState}
              changeHistory={compLibraryHistory.items}
              workspaceUsers={workspaceUsers}
              setCommentState={setCommentState}
              selectComp={selectComp}
              selectedId={selectedId}
              fetchLibraryHistoryNewPage={fetchLibraryHistoryNewPage}
              refreshLibraryHistory={refreshLibraryHistory}
              updateCompResultComments={updateCompResultComments}
              setShowCompError={setShowCompError}
              allHistoryFetched={allHistoryFetched}
              setLoadingHistory={setLoadingHistory}
              handleChangeItemClick={handleChangeItemClick}
              handleCommentChangeClick={handleCommentChangeClick}
            />
          )}
          {!allHistoryFetched && !loadingHistory && (
            <div className={style.loadMore} onClick={() => fetchLibraryHistoryNewPage()}>
              <div>Load more</div>
              <ArrowDownWardIcon className={style.icon} />
            </div>
          )}
        </div>
      )}
      {panelState === PANELS.comp_library.merge_suggestions && (
        <ComponentMergeSuggestionPanel
          selectedFolder={selectedFolder}
          className={style.componentMergeSuggestionPanel}
          {...componentMergeSuggestionState}
        />
      )}
    </div>
  );
};

export default CompDetail;
