import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  /** The icon to be rendered. Intended for use with @mui/icons-material.
   *  You can pass in an icon like Icon={\<CheckIcon \/>}.
   *  You can also pass in a straight-up SVG element like Icon={\<svg>...\</svg>}.
   */
  Icon: React.ReactNode | React.ReactSVGElement;
  size?: "base" | "small" | "xs" | "xxs";
  color?: "primary" | "secondary" | "minimal" | "positive" | "warning" | "danger" | "blue" | "inherit";
}

export function Icon(props: IProps) {
  const iconColor = props.color ?? "primary";

  return (
    <div
      className={classNames(
        style.IconWrapper,
        {
          [style.base]: !props.size || props.size === "base",
          [style.small]: props.size === "small",
          [style.xs]: props.size === "xs",
          [style.xxs]: props.size === "xxs",
          [style[iconColor]]: true,
        },
        props.className
      )}
      style={{
        ...props.style,
      }}
      data-testid="icon"
    >
      {props.Icon && props.Icon}
    </div>
  );
}

export default Icon;
