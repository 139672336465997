import React, { useState } from "react";
import AddVariableModal from "../AddVariableModal";

import RichTextVariableInput from "./RichTextVariableInput";
import { Label, LabelRight } from "./components";
import style from "./style.module.css";

/**
 *
 * @param {*} param0
 * @returns
 */
const VariableRichTextArea = ({
  placeholder,
  value,
  isDisabled,
  isBaseText,
  isVariant,
  hideLabels = false,
  showContentLength,
  showContentLengthOnFocusOnly,
  hideCharacterLimit,
  disableCharacterLimit,
  isPlural,
  handleTextChange,
  components,
  disableRichText,
  textLabelLeft,
  textInputClassName,
  accessEditorInstance,
  highlightBrackets,
  handleCharacterLimitChange,
  handleCharacterLimitSubmit,
  inSampleProject,
}) => {
  const [showVariableBtn, setShowVariableBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentLength, setContentLength] = useState(0);
  const [currentSelection, setCurrentSelection] = useState("");
  const [insertedVariable, setInsertedVariable] = useState(null);
  const [contentLengthVisible, setContentLengthVisible] = useState(!showContentLengthOnFocusOnly && showContentLength);

  const hideVariableModal = () => {
    setShowModal(false);
  };
  const showVariableModal = () => {
    setShowModal(true);
    setTimeout(() => {
      setShowVariableBtn(true);
    }, 150);
  };

  const onInsertVariable = (selectedVariable) => {
    window.analytics?.track("Variable Inserted", {
      type: selectedVariable.type,
    });
    setInsertedVariable(selectedVariable);
  };

  const onFocus = () => {
    if (showContentLengthOnFocusOnly) setContentLengthVisible(true);
    setShowVariableBtn(true);
  };

  const onBlur = () => {
    if (showContentLengthOnFocusOnly) setContentLengthVisible(false);
    setTimeout(() => {
      setShowVariableBtn(false);
    }, 100);
  };

  const getLabelText = () => {
    if (isBaseText && isVariant) return "Base Text";
    if (isBaseText && !isVariant) return "Text";
    return "Variant Text";
  };

  return (
    <div className={style.container}>
      {!hideLabels && (
        <div className={style.titleWrapper}>
          <Label components={components} getLabelText={getLabelText} textLabelLeft={textLabelLeft} />
          <LabelRight
            key={value.characterLimit}
            components={components}
            showVariableButton={showVariableBtn || !isPlural}
            showVariableModal={showVariableModal}
            contentLength={contentLength}
            buttonText="+ Insert Variable"
            showContentLength={contentLengthVisible}
            characterLimit={value.characterLimit}
            handleCharacterLimitChange={handleCharacterLimitChange}
            handleCharacterLimitSubmit={handleCharacterLimitSubmit}
            disableCharacterLimit={disableCharacterLimit}
            hideCharacterLimit={hideCharacterLimit}
          />
        </div>
      )}
      <RichTextVariableInput
        highlightBrackets={highlightBrackets}
        placeholder={placeholder}
        isDisabled={isDisabled}
        handleTextChange={handleTextChange}
        contentLength={contentLength}
        setContentLength={setContentLength}
        setCurrentSelection={setCurrentSelection}
        onFocus={onFocus}
        onBlur={onBlur}
        initialValues={value}
        insertedVariable={insertedVariable}
        disableRichText={Boolean(disableRichText)}
        showVariableModal={showVariableModal}
        textInputClassName={textInputClassName}
        accessEditorInstance={accessEditorInstance}
        characterLimit={value.characterLimit}
      />

      {showModal && (
        <AddVariableModal
          type="select"
          defaultExample={currentSelection}
          onHide={hideVariableModal}
          onInsertVariable={onInsertVariable}
          inSampleProject={inSampleProject}
        />
      )}
    </div>
  );
};

export default VariableRichTextArea;
